import axios from "axios"

const apiKey = 'API-KEY';

const url = `${process.env.VUE_APP_FUSION_API_URL}/company_billing/`;
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const warehouseAPI = process.env.VUE_APP_WAREHOUSE_API_URL
const AUTH_TOKEN = process.env.VUE_APP_WAREHOUSE_API_AUTH_TOKEN


axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

export default {
  async getDetailedBillingData(request) {
    let requestString = '';
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        const value = request[key];
        requestString += `&${key}=${value}`
      }
    }
    let res = await axios.get(`${url}detailed_billing_data?${apiKey}=${API_KEY}${requestString}`);    
    return res.data.data;
  },
  async getXeroBillingData(request) {   
    let requestString = '';
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        const value = request[key];
        requestString += `&${key}=${value}`
      }
    }
    let res = await axios.get(`${url}xero_billing_data?${apiKey}=${API_KEY}${requestString}`);   
    return res.data.data;
  },
  async getInvoiceNumbers() {   
    let params = {
      "API-KEY": API_KEY,
      timestamp: new Date().getTime() 
    }
    let res = await axios.get(`${url}invoice_numbers`, {params: params});   
    return res.data.data;
  },
  async updateCharge(json) {
    var myHeaders = new Headers(); 
    let urlencoded = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        const element = json[key];
        urlencoded.append(key, element)        
      }
    }   
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append('API-KEY', API_KEY);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    let result = await fetch(`${url}edit_commited_data`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;   
  },
  async deleteCharge(formData) {
    var myHeaders = new Headers();    
    let formArray = formData;
    formArray.append("API-KEY", API_KEY);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formArray,
      redirect: 'follow'
    };
    let result = await fetch(`${url}delete_commited_data`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;
  },
  async checkDataThenFinalCommit(json) {
    var myHeaders = new Headers(); 
    let urlencoded = new URLSearchParams();
    urlencoded.append('data', JSON.stringify(json));
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append('API-KEY', API_KEY);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    let result = await fetch(`${url}final_commit`, requestOptions)
      .then(response => response.json())
      .then(result => this.result = result)
    return result;   
  },
  async getGoCardlessTemplate(){
    let res = await axios.get(`${url}go_cardless_billing?${apiKey}=${API_KEY}`);    
    return res.data.data;
  },
  async sendRevenuePerPalletByCompany(invoiceNumber){
    let res = await axios.get(`${url}send_revenue_per_pallet_email?${apiKey}=${API_KEY}&invoiceNumber=${invoiceNumber}`);    
    return res.data.data;
  },
  async createPDFInvoice(companyID, invoiceNumber){
    let res = await axios.get(`${url}create_pdf_invoice?${apiKey}=${API_KEY}&companyId=${companyID}&invoiceNumber=${invoiceNumber}`);    
    return res.data;
  },
  async getCompanyBillingXero(params){
    let url = `${warehouseAPI}/billing/company-billing-xero`;
    params.timestamp = new Date().getTime() 
    let res = await axios.get(url,  {params: params});
    return res.data.data;
  },
  async getCompanyBillingCommitted(params){
    let url = `${warehouseAPI}/billing/company-billing-committed`;
    params.timestamp = new Date().getTime() 
    let res = await axios.get(url,  {params: params});
    return res.data.data;
  },
  async sendUncommittedDataToAccountManager(invoiceNumber){
    let reqBody = {invoice_number: invoiceNumber }
    let url = `${warehouseAPI}/billing/send-uncommitted-data-to-account-manager`;
    let res = await axios.post(url, reqBody);
    return res.data;
  },
}